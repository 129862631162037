import { axios } from '@/utils/request'

/**
* 模块名:
* 代码描述: 关联关系分页查询
* 作者:陈莉莉
* 创建时间:2022-07-19 15:55:10
*/
export function findLinkPageList(parameter){
    return axios({
        url: '/broadcastGoodsCategory/broadcastGoodsCategoryList',
        method: 'post',
        data: parameter
    })
}