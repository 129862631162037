<template>
  <!-- 商品分类页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="6" :sm="24">
                  <!-- 分类名称 -->
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="请输入搜索关键词" />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button
                    style="margin-left: 8px; margin-right: 8px"
                    @click="() => ((queryParam = {}), initTableData())"
                    >重置</a-button
                  >
                  <a-button type="primary" @click="$refs.addForm.add(queryParam.goodsCategoryId)"
                    >关联直播分类</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 外层表格 -->
          <a-table
            style="font-size: 26px"
            :pagination="false"
            :scroll="{ x: 1300 }"
            :columns="columns"
            :data-source="data"
            :loading="loading"
          >
            <!-- logo -->
            <!-- <span slot="logo" slot-scope="text, record">
              <img :src="record.logo" alt="">
            </span> -->
            <span slot="name" slot-scope="text" style="font-weight: 600">
              {{ text }}
            </span>
            <!-- 是否公用 -->
            <span slot="isPublic" slot-scope="text" style="font-weight: 600">
              {{ text == 1 ? '是' : text == 2 ? '否' : '' }}
            </span>
            <span slot="sort" slot-scope="text" style="font-weight: 600">
              {{ text }}
            </span>
            <!-- 操作 -->
            <span slot="action" slot-scope="text, record" style="font-weight: 600">
              <a @click.stop="$refs.editForm.edit(record)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
                <a @click.stop="">删除</a>
              </a-popconfirm>
            </span>
            <!-- 更新时间 -->
            <span slot="updateTime" slot-scope="text" style="font-weight: 600">
              {{ text ? text : '未修改' }}
            </span>
            <!-- 分佣比例 -->
            <span slot="ratio" slot-scope="text" style="font-weight: 600">
              {{ text ? text.toFixed(2) : '未设置' }}
            </span>
            <span slot="agentRatio" slot-scope="text" style="font-weight: 600">
              {{ text ? text.toFixed(2) : '未设置' }}
            </span>
            <!-- 状态 -->
            <span slot="displayStatus" slot-scope="text, record">
              <a-tag style="padding: 3px 10px" :color="record.delFlag == 1 ? '#87d068' : '#f50'">
                <span style="font-weight: 600">
                  {{ record.delFlag == 1 ? '启用' : '禁用' }}
                </span>
              </a-tag>
            </span>
          </a-table>
        </div>
      </a-col>
    </a-row>

    <add-form ref="addForm" @ok="initTableData" />
  </div>
</template>
<script>
import { findLinkPageList } from '@/api/modular/mallLiving/linkList'

import addForm from './addLinkForm'

const columns = [
  {
    title: '序号',
    align: 'left',
    width: '80px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '直播分类名称',
    align: 'left',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {
      customRender: 'name',
    },
  },
  {
    title: '省',
    align: 'center',
    dataIndex: 'province',
  },
  {
    title: '市',
    align: 'center',
    dataIndex: 'city',
  },
  {
    title: '关联时间',
    align: 'center',
    dataIndex: 'createTime',
  },
]

export default {
  data() {
    return {
      data: [],
      columns,
      loading: false,
      allList: [],
      innerData: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0, //总数据条数
      statusList: [
        {
          status: 1,
          name: '启用',
        },
        {
          status: 3,
          name: '禁用',
        },
      ],
      classList: [], //筛选商品分类列表项
      queryParam: {
        goodsCategoryId: undefined,
        keywords: undefined,
      },
    }
  },
  mounted() {
    this.initTableData()
  },
  components: {
    addForm,
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    //初始化页面
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    //获取列表数据
    getList() {
      this.loading = true
      this.queryParam.goodsCategoryId = this.$route.query.categoryId
      findLinkPageList(this.queryParam)
        .then((res) => {
          if (res.success) {
            this.data = res.data
            this.loading = false
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    sysItemDelete(record) {
      commodityClassDelete({
        id: record.id,
        delFlag: '2',
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success('删除成功')
          this.getList()
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
// @import url(); 引入公共css
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  user-select: none;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 50px;
  height: 50px;
}
</style>
